export const BUSINESS_ERROR_CONFIG = 'error_logic_configuration';
export const MANUAL_ALERTS = 'alert-diagnostics';
export const REAL_TIME = 'noc';
export const OVERVIEW_DASHBOARD = 'overview-dashboard';
export const METRIC_LENS = 'metric-lens';
export const VIEWER_MODULE_PULSE5 = 'viewer-module-pulse5';
export const INTEGRATED_APP_MANAAGER = 'integrated_app_manager';
export const AI_ALERTS_PULSE5 = 'ai-alerts-pulse5';
export const AI_ALERTS = 'ai_alerts';
export const AD_AI_ALERTS = 'ad_ai_alerts';
export const AUTO_INSIGHTS = 'auto-insights';
export const CUSTOMIZABLE_DASHBOARD = 'customizable-dashboard';
export const AI_ROADMAP = 'ai-roadmap-dashboard';

export const ECO_TRENDS = 'app-experience';
export const APP_USER_TIMELINE = 'app-user-timeline';
export const APP_AI_ALERTS = 'app-ai-alerts';
export const CONTRACT_LEVEL_USAGE_DASHBOARD = 'contract-level-usage-dashboard';
export const AD_INSIGHTS = 'ad-insights';
export const PROMPT = 'prompt';
export const DIMENSION_MANAGEMENT = 'dimension-management';

// MINI_ODP
export const MINI_ODP_ACCESS = 'mini_odp_access';

// ECO_SERVER
export const ECO_SERVER_ACCESS = 'enable-eco-server';

// ACTIVATION_PREVIEW
export const ACTIVATION_PREVIEW_ACCESS = 'activation-preview';

export const SERVICE_INTEGRATION_ENABLED = 'service-integration-pagerduty-enabled-feature';
export const RESILIENCY = 'resiliency';
